<template>
  <div class="shootVideo">
    <mt-header :title="$t('shootVideo')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="tips">{{ $t('shootVideoTips') }}</div>
      <div class="value">{{
        $t('shootVideoValue', {
          name: user.name || '---',
          amount: applyAmount,
          org: $t('projectName')
        })
      }}</div>
      <div class="update">
        <update-file
          type="AUTH_VIDEO"
          @change="onUploadHandheld"
          accept="video/*"
          :url="oneVideo"
        >
          <img src="../../assets/holdIdVideo.png">
        </update-file>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { oneVideo, queryStatus } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
import updateFile from '@/components/updateFile.vue'
export default {
  name: 'ShootVideo',
  components: {
    callPhone,
    updateFile,
  },
  data () {
    return {
      oneVideo: oneVideo,
    }
  },
  computed: {
    ...mapState(["user", "queryStatus"]),
    applyAmount() {
      try {
        return this.$options.filters['formatMoney']((this.queryStatus.apply || {}).applyAmount)
      } catch(e) {
        return '---'
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
     ...mapMutations(["SET_QUERY_STATUS"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await Promise.all([
        this.getAueryStatus(),
      ])
      this.$indicator.close()
      this.$NProgress.done()
    },
    async getAueryStatus() {
      await this.$axios({
        method: "get",
        url: queryStatus,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_QUERY_STATUS(e.body);
        }
      }).catch(() => {})
    },
    onUploadHandheld() {
      this.$toast(this.$t('uploadedSuccess'));
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.shootVideo {
  .content {
    .tips {
      margin: 40px 30px 0px 30px;
    }
    .value {
      margin: 30px 20px 0px 20px;
      padding: 15px;
      border-radius: 20px;
      background-color: #4F9B41;
      color: white;
    }
    .update {
      width: 221px;
      margin: 45px auto;
    }
  }
}
</style>